import jails from 'jails-js';

import * as ibbar from './_ib-bar';
import * as backbutton from './_back-button';
import * as header from './_header';
import * as search from './_search';
import * as searchresult from './_search-result';
import * as menu from './_menu';
import * as chat from './_chat';
import * as popup from './_popup';
import * as cardlist from './_card-list';
import * as serviceonline from './_service-online';
import * as cardinfo from './_card-info';
import * as bannerflash from './_banner-flash';
import * as filteroffer from './_filter-offer';
import * as offerlist from './_offer-list';
import * as assistancelist from './_assistance-list';
import * as assistancemodal from './_assistance-modal';
import * as videolist from './_video-list';
import * as faqlist from './_faq-list';
import * as legaltext from './_legal-text';
import * as discontinuedlist from './_discontinued-list';
import * as servicepercard from './_service-per-card';
import * as cancellationform from './_cancellation-form';
import * as requestfollowup from './_request-follow-up';
import * as bannerhome from './_banner-home';
import * as bloglist from './_blog-list';
import * as tracking from './_tracking';

jails.register('ib-bar', ibbar);
jails.register('back-button', backbutton);
jails.register('header', header);
jails.register('search', search);
jails.register('search-result', searchresult);
jails.register('menu', menu);
jails.register('chat', chat);
jails.register('popup', popup);
jails.register('card-list', cardlist);
jails.register('service-online', serviceonline);
jails.register('card-info', cardinfo);
jails.register('banner-flash', bannerflash);
jails.register('filter-offer', filteroffer);
jails.register('offer-list', offerlist);
jails.register('assistance-list', assistancelist);
jails.register('assistance-modal', assistancemodal);
jails.register('video-list', videolist);
jails.register('faq-list', faqlist);
jails.register('legal-text', legaltext);
jails.register('discontinued-list', discontinuedlist);
jails.register('service-per-card', servicepercard);
jails.register('cancellation-form', cancellationform);
jails.register('request-follow-up', requestfollowup);
jails.register('banner-home', bannerhome);
jails.register('blog-list', bloglist);
jails.register('tracking', tracking);

jails.start();

$(window).ready(function (e) {
  const target = location.hash.replace('#', '');
  if (target != '') {
    const $target = $(`[data-anchor="${target}"]`);
    $('html, body')
      .stop()
      .animate(
        {
          scrollTop: $target.offset().top - 100,
        },
        1000,
      );
  }
});
