export default function faqlist({ main, elm }) {
  main((_) => [init]);

  const init = () => {
    $(elm)
      .find('.box__title')
      .on('click', function () {
        if ($(this).parent().hasClass('active')) {
          $(elm).find('.box').removeClass('active');
        } else {
          $(elm).find('.box').removeClass('active');
          $(this).parent().addClass('active');
        }
      });
    $(elm)
      .find('.item__title')
      .on('click', function () {
        if ($(this).parent().hasClass('active')) {
          $(elm).find('.box__item').removeClass('active');
        } else {
          $(elm).find('.box__item').removeClass('active');
          $(this).parent().addClass('active');
        }
      });
  };
}
