import 'jquery-mask-plugin';
export default function ibbar({ main, elm }) {
  main((_) => [init]);

  const init = () => {
    $('.btn-acesso').on('click', function () {
      $('.container-bar__responsive').hide();
      if ($(this).hasClass('acesso-correntista')) {
        $('.correntista').css('display', 'inline-block');
        $('.topbar .container-bar').addClass('choosedCorrentista');
      } else {
        $('.nao-correntista').css('display', 'inline-block');
        $('.topbar .container-bar').addClass('choosedNaoCorrentista');
      }
    });
    // detectar Mobile
    var isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
      },
    };
    if (isMobile.Android() || isMobile.iOS() || isMobile.Windows()) {
      // topbar
      $('.container-bar, .container-bar__responsive').css('display', 'none');
      $('.container-bar__mobile').css('display', 'block');
      // modal de acesso ao IB
      $('.modal.access-card').find('.header-modal').addClass('mobile');
      $('.modal.access-card').find('.content-modal').addClass('mobile');
      $('.modal.access-card').find('.access-content_title').addClass('mobile');
      $('.modal.access-card').find('.access-content h7').addClass('mobile');
      $('.modal.access-card').find('.header-modal h5').text('Acesse seu cartão pelo app');
      $('.modal.access-card').find('.access-content').hide();
      $('.modal.access-card').find('.content-modal__col-2').hide();
      $('.modal.access-card').find('.content-modal-hr').hide();
      $('.modal.access-card').find('.access-content.mobile').show();

      // to-do copy to css:
      $('.overlay').css('z-index', '999');
      $('.wrapper-modal').css('z-index', '9999');
      $('.btn-baixarapp-access').attr('target', '_blank');

      // inserir links para download do app
      if (isMobile.Windows()) {
        $('.btn-baixarapp, .btn-baixarapp-access').attr('href', 'https://www.microsoft.com/pt-br/store/apps/bradesco/9wzdncrfj2cs');
      }
      if (isMobile.iOS()) {
        $('.btn-baixarapp, .btn-baixarapp-access').attr('href', 'https://itunes.apple.com/br/app/bradesco/id1073889634?mt=8');
      }
      if (isMobile.Android()) {
        $('.btn-baixarapp, .btn-baixarapp-access').attr('href', 'https://play.google.com/store/apps/details?id=br.com.bradesco.cartoes&hl=pt_BR');
      }
    }
    // quando escolher fechar barra no mobile, remover topbar
    $('.fechar').on('click', function () {
      $('.topbar').fadeOut();
      $('.header').css('top', '0px');
      $('.menu').css('top', '40px').css('height', 'calc(100vh - 40px)');
      $('.search').css('top', '40px');
    });
    //pular para próximo input
    $('.form-correntista input').on('keyup', function () {
      if (this.value.length == $(this).attr('maxlength')) {
        var next = $(this).prop('tabindex') + 1;
        $('.form-correntista').find(`input[tabindex=${next}]`).focus();
      }
    });
    // validando form do correntista
    $('.form-correntista').on('submit', function (e) {
      e.preventDefault();
      var valid = false;
      if ($(this).find('input[name="AGN"]').val().length < 1) {
        alert('Favor preencher o campo agência.');
      } else if ($(this).find('input[name="CTA"]').val().length < 1) {
        alert('Favor preencher o campo conta.');
      } else if ($(this).find('input[name="DIGCTA"]').val().length < 1) {
        alert('Favor preencher o campo dígito.');
      } else {
        valid = true;
      }
      if (valid) this.submit();
    });
    // validando form do nao correntista
    function cpf(cpf) {
      var cpf = cpf.replace(/\D/g, '');
      if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
      var result = true;
      [9, 10].forEach(function (j) {
        var soma = 0,
          r;
        cpf
          .split(/(?=)/)
          .splice(0, j)
          .forEach(function (e, i) {
            soma += parseInt(e) * (j + 2 - (i + 1));
          });
        r = soma % 11;
        r = r < 2 ? 0 : 11 - r;
        if (r != cpf.substring(j, j + 1)) result = false;
      });
      return result;
    }
    $('.form-naocorrentista').on('submit', function (e) {
      e.preventDefault();
      var valid = false;
      if ($(this).find('input[name="CPF"]').val().length < 1) {
        alert('Favor preencher o campo CPF.');
      } else {
        var valor = $(this).find('input[name="CPF"]').val().replace(/\D/g, '');
        if (cpf(valor) === false) {
          alert('CPF inválido. Por favor, digite novamente.');
        } else {
          valid = true;
        }
        if (valid) this.submit();
      }
    });
    $('.form-naocorrentista input[name="CPF"]').mask('999.999.999-99');
  };
}
