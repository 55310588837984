export default function popup({ main, elm }) {
  main((_) => [init]);

  const init = () => {
    $(elm)
      .find('.popup__close')
      .on('click', function () {
        $(elm).removeClass('active');
      });
  };
}
