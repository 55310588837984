import 'slick-carousel/slick/slick';

export default function bannerflash({ main, elm }) {
  main((_) => [init, carousel]);
  const init = () => {};
  const carousel = () => {
    $(elm).find('[data-carousel]').slick({
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: false,
      dots: true,
    });
  };
}
