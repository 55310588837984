export default function serviceonline({ main, elm }) {
  main((_) => [init, detectMobile]);
  const init = () => {
    openStep();
    switchEnv();
  };
  const openStep = () => {
    $(elm)
      .find(`.services__item`)
      .on('click', function () {
        if (!$(this).hasClass('active')) {
          reset();
          $(this)
            .toggleClass('active')
            .closest('.services__env')
            .toggleClass('active')
            .find(`.content__item#${$(this).attr('data-step')}`)
            .toggleClass('active');
          // scroll to top of steps filter section
          $('html, body').animate(
            {
              scrollTop: $(this).offset().top - 150,
            },
            'fast',
          );
        } else {
          reset();
        }
      });
  };
  const switchEnv = () => {
    $(elm)
      .find(`.filter__option--input`)
      .on('change', function () {
        $('.filter__switch--slider').toggleClass('active');
        $('.services__env').hide();
        $(`.services__env#${$(this).val()}`).show();
        reset();
      });
    $(elm)
      .find('.filter__switch')
      .on('click', function () {
        // TO-DO: make this function work without saying env name
        if ($('.filter__switch--slider').hasClass('active')) {
          $('.filter__option[for="filter__option-app"]').click();
        } else {
          $('.filter__option[for="filter__option-ib"]').click();
        }
      });
  };
  const reset = () => {
    $(elm).find('.services__env').removeClass('active').find('*').removeClass('active');
  };
  const detectMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      $(elm).find('.appstores').find('a[href*="google"]').addClass('active');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      $(elm).find('.appstores').find('a[href*="apple"]').addClass('active');
    } else {
      $(elm).find('.appstores').find('a').addClass('active');
    }
  };
}
