import 'iframe-resizer';

export default function requestfollowup({ main, elm }) {
  main((_) => [init]);
  const init = () => {
    iFrameResize(
      {
        log: false,
        onMessage: function (messageData) {
          if (messageData.message === 'Formulário enviado') {
            $('html, body')
              .stop()
              .animate({ scrollTop: $('#cancellation-form iframe').offset().top - 160 }, 500);
          }
          if (messageData.message == 'Select aberto') {
            const iframetop = $('#cancellation-form iframe').offset().top;
            const scrolltop = $(window).scrollTop();
            $(elm).find('iframe').css('max-height', `calc(100vh - (${iframetop}px - ${scrolltop}px))`);
            $('body').addClass('locked');
          }
          if (messageData.message == 'Select fechado') {
            $(elm).find('iframe').css('max-height', 'none');
            $('body').removeClass('locked');
          }
        },
      },
      '#cancellation-form iframe',
    );

    $('[href="#request-follow-up"]').on('click', function () {
      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: $(`[data-anchor="${$(this).attr('href').replace('#', '')}"]`).offset().top - 100,
          },
          500,
        );
    });
  };
}
