import 'iframe-resizer';

export default function bloglist({ main, elm }) {
  main((_) => [init]);
  const init = () => {
    iFrameResize(
      {
        log: false,
      },
      '#blog-list iframe',
    );
  };
}
