import 'slick-carousel/slick/slick';
import 'slick-carousel/slick/slick';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import './vendors/select2';

export default function filteroffer({ main, elm }) {
  const couponCode = `.coupon__code`;
  main((_) => [init, carousel, select, unique, selectChange, urlCardParams, selectPartner, listOffers, hideSections, pagination, tooltip, copy]);

  const init = () => {};

  const carousel = () => {
    const countItemsSlide = $(elm).find('.filter__item').length;
    if (countItemsSlide > 6) {
      $(elm).find('[data-carousel]').slick({
        arrows: true,
        dots: false,
        slidesToShow: 6.5,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: false,
      });
    }

    if ($(window).width() < 1280) {
      $(elm)
        .find('[data-carousel]')
        .slick({
          arrows: true,
          dots: false,
          slidesToShow: 1.5,
          slidesToScroll: 1,
          variableWidth: true,
          infinite: false,
          mobileFirst: true,
          responsive: [
            {
              breakpoint: 539,
              settings: {
                slidesToShow: 2.5,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 639,
              settings: {
                slidesToShow: 2.5,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 959,
              settings: {
                slidesToShow: 4.5,
                slidesToScroll: 1,
              },
            },
          ],
        });

      if (countItemsSlide <= 1) {
        $(elm).find('[data-carousel]').slick('unslick');
      }
    }
  };

  const select = () => {
    $(elm).find('select').select2({
      dropdownAutoWidth: true,
      minimumResultsForSearch: -1,
      placeholder: 'Selecionar',
    });
  };

  const unique = (list) => {
    const result = [];
    $.each(list, function (i, e) {
      if ($.inArray(e, result) == -1) result.push(e);
    });
    return result;
  };

  const selectChange = () => {
    $('.filter__item').first().addClass('active');

    const nameSelect = $('.filter__item.item.active').attr('data-paginated-filter-partner');

    if (nameSelect !== undefined) {
      const nameSelect2 = $('.filter__item.item.active')
        .attr('data-paginated-filter-partner')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      if (nameSelect2) {
        $('.filter__select--select')
          .val([`${nameSelect2}`])
          .trigger('change');

        if (nameSelect2.includes('&')) {
          const nameSelect3 = nameSelect2.replace(/&/g, 'and');
          $('.filter__select--select')
            .val([`${nameSelect3}`])
            .trigger('change');
        }
      }
    }
  };

  const urlCardParams = () => {
    const url = window.location.href;

    if (url.includes('cartao')) {
      $(elm).find('.filter__item.active').removeClass('active');

      const urlCard = url.split('/?cartao=')[1];
      const findCard = $(elm).find('.filter-offer_cards__list').find(`[data-filter-url="${urlCard}"]`);
      findCard.addClass('active');
      // const urlOffersCard = url.split('/?cartao=')[0].split('/ofertas/')[1];
      // const nameSelectCard = urlOffersCard.toLowerCase();
      // $('.filter__select--select')
      //   .val([`${nameSelectCard}`])
      //   .trigger('change');

      // const findCardCarousel = findCard.closest('[data-slick-index]');
      // const selectCardCarousel = findCardCarousel.attr('data-slick-index');
      // $(elm).find('[data-carousel]').slick('slickGoTo', selectCardCarousel);
    }
  };

  const selectPartner = () => {
    const FILTER_SECTION = $(elm).find('[data-paginated="filter"]')[0];

    $(FILTER_SECTION).on('change', '.filter__select--select', function () {
      const url = $(this).find(':selected').attr('data-url');
      window.location.replace(url);
      $(elm).find('.filter-offer_alert').hide();
    });
  };

  const listOffers = () => {
    const OFFERS_SECTION = $(elm).find('.list__item');
    const CARDS_SECTION = $(elm).find('.filter__item');

    $(OFFERS_SECTION).each(function (index, item) {
      $(elm).find('.filter-offer_alert').hide();
      $(item).hide();
      const listOfferItem = $(item).attr('data-filter');
      const filterCardItem = $('.filter__item.active').attr('data-filter');

      if (listOfferItem === filterCardItem) {
        $(item).addClass('active');
        $(item).show();
        const filterButton = $(item).attr('data-filter');
        $(elm).find('[data-paginated-button="next"]').attr('data-filter', `${filterButton}`);
      }
    });

    const selectOptions = unique(
      $($(elm).find('.list__item.active')).map(function (index, item) {
        return [...$(this).attr(`data-filter-tags`).split(',')];
      }),
    );

    const filterOffers = $(elm).find('.list__item.active').attr('data-filter');

    $('.filter__tags-items ul').append($(`<li class="filter__tag-item active" data-filter-tags='todos' data-filter='${filterOffers}'>todos</li>`));
    const filtered = selectOptions.filter(function (value) {
      return value != '' && value != null;
    });

    $.each(filtered.sort(), function (i, value) {
      const filterOffers = $(elm).find('.list__item.active').attr('data-filter');
      $('.filter__tags-items ul').append($(`<li class="filter__tag-item" data-filter-tags='${value}' data-filter='${filterOffers}'></li>`).html(value));
    });

    const OFFERS_TAG = $(elm).find('.filter__tag-item');

    $(CARDS_SECTION).on('click', function () {
      $(CARDS_SECTION).removeClass('active');
      $(this).toggleClass('active');

      $(OFFERS_SECTION).each(function (index, item) {
        $(item).removeClass('active');
        $(item).hide();

        const listOfferItem = $(item).attr('data-filter');
        const filterCardItem = $('.filter__item.active').attr('data-filter');

        if (listOfferItem === filterCardItem) {
          $(item).addClass('active');
          $(item).show();
          const filterButton = $(item).attr('data-filter');
          $(elm).find('[data-paginated-button="next"]').attr('data-filter', `${filterButton}`);
        }
      });

      $(OFFERS_TAG).each(function (index, item) {
        $(item).removeClass('active');
        $(item).hide();
        const listOfferItem = $('.list__item.active').attr('data-filter');
        const filterTagsItem = $(item).attr('data-filter');

        if (listOfferItem === filterTagsItem) {
          $(item).show();

          const filterTagAll = $(item).attr('data-filter-tags');

          if (filterTagAll === 'todos') {
            $(item).addClass('active');
          }
        }
      });

      $('.filter__tag-item').remove();

      const selectOptions = unique(
        $($(elm).find('.list__item.active')).map(function (index, item) {
          return [...$(this).attr(`data-filter-tags`).split(',')];
        }),
      );
      const filterOffers = $(elm).find('.list__item.active').attr('data-filter');
      $('.filter__tags-items ul').append($(`<li class="filter__tag-item active" data-filter-tags='todos' data-filter='${filterOffers}'>todos</li>`));
      const filtered = selectOptions.filter(function (value) {
        return value != '' && value != null;
      });
      $.each(filtered.sort(), function (i, value) {
        const filterOffers = $(elm).find('.list__item.active').attr('data-filter');
        $('.filter__tags-items ul').append($(`<li class="filter__tag-item" data-filter-tags='${value}' data-filter='${filterOffers}'></li>`).html(value));
      });

      $(elm)
        .find('.filter__tag-item')
        .on('click', function () {
          $(elm).find('.filter__tag-item').removeClass('active');
          $(this).toggleClass('active');

          $(OFFERS_SECTION).each(function (index, item) {
            $(item).hide();
            const listTagsItem = $(item).attr('data-filter-tags');
            const filterTagsItem = $('.filter__tag-item.active').attr('data-filter-tags');
            const listOfferItem = $(item).attr('data-filter');
            const filterCardItem = $('.filter__item.active').attr('data-filter');

            if (listTagsItem === filterTagsItem && listOfferItem === filterCardItem) {
              $(item).addClass('active');
              $(item).show();
            }

            if (filterTagsItem === 'todos') {
              if (listOfferItem === filterCardItem) {
                $(item).addClass('active');
                $(item).show();
              }
            }
          });
        });

      const OFFERS_SECTION_ACTIVE = $(elm).find('.list__item.active');

      if (OFFERS_SECTION_ACTIVE.length < 0) {
        $(elm).find('.filter-offer_offers__list').hide();
        $(elm).find('.filter-offer_alert').hide();
        $(elm).find('.filter-offer_offers__list-alert').show();
      } else if (OFFERS_SECTION_ACTIVE.length <= 0) {
        $(elm).find('.filter-offer_offers__list').hide();
        $(elm).find('.filter-offer_alert').hide();
        $(elm).find('.filter-offer_offers__list-alert').show();
      } else {
        $(elm).find('.filter-offer_alert').hide();
        $(elm).find('.filter-offer_offers__list-alert').hide();
        $(elm).find('.filter-offer_offers__list').show();
      }
    });

    $(OFFERS_TAG).on('click', function () {
      $(OFFERS_TAG).removeClass('active');
      $(this).toggleClass('active');

      $(OFFERS_SECTION).each(function (index, item) {
        $(item).hide();
        const listTagsItem = $(item).attr('data-filter-tags');
        const filterTagsItem = $('.filter__tag-item.active').attr('data-filter-tags');
        const listOfferItem = $(item).attr('data-filter');
        const filterCardItem = $('.filter__item.active').attr('data-filter');

        if (listTagsItem === filterTagsItem && listOfferItem === filterCardItem) {
          $(item).addClass('active');
          $(item).show();
        }

        if (filterTagsItem === 'todos') {
          if (listOfferItem === filterCardItem) {
            $(item).addClass('active');
            $(item).show();
          }
        }
      });
    });

    const OFFERS_TAG_BUTTON = $(elm).find('.filter__tags-items-button');

    $(OFFERS_TAG_BUTTON).on('click', function () {
      $(this).toggleClass('active');
      $(this).next().toggleClass('active');
    });
  };

  const tooltip = () => {
    tippy(couponCode, {
      content: 'O código do cupom foi copiado',
      trigger: 'click',
      theme: 'bradescard',
      onShow(instance) {
        setTimeout(() => {
          instance.hide();
        }, 2000);
      },
    });
  };

  const copy = () => {
    $(couponCode).on('click', function () {
      const $temp = $('<input>');
      $('body').append($temp);
      $temp.val($(this).text()).select();
      document.execCommand('copy');
      $temp.remove();
    });
  };

  const pagination = () => {
    const PAGINATION_SECTION = $(elm).find('[data-paginated="pagination"]')[0];
    const LIST_SECTION = $(elm).find('[data-paginated="list"]')[0];

    let perPage = 6;
    if ($(window).width() < 1280) {
      perPage = 6;
    }
    if ($(window).width() < 960) {
      perPage = 6;
    }
    if ($(window).width() < 640) {
      perPage = 6;
    }

    const startPagination = () => {
      const OFFERS_SECTION = $(elm).find('.list__item');
      $(OFFERS_SECTION).each(function (index, item) {
        $(LIST_SECTION).find(`> *.active`).hide();
        $(LIST_SECTION).find(`> *.active:lt(${perPage})`).show();
      });
    };

    const hidePagination = () => {
      if ($(LIST_SECTION).find(`> *:visible`).length >= $(LIST_SECTION).find(`> *.active`).length) {
        $(PAGINATION_SECTION).hide();
      } else {
        $(PAGINATION_SECTION).show();
      }
    };

    startPagination();
    hidePagination();

    $(elm)
      .find(PAGINATION_SECTION)
      .on('click', '[data-paginated-button="next"]', function () {
        const nextPage = parseInt($(this).attr('data-paginated-currpage')) + 1;
        const filterButton = $(elm).find('[data-paginated-button="next"]').attr('data-filter');
        $(LIST_SECTION)
          .find(`> *.active:lt(${perPage * nextPage}), [data-filter="${filterButton}"]`)
          .show();
        $(this).attr('data-paginated-currpage', nextPage);
        hidePagination();
      });

    const CARDS_SECTION = $(elm).find('.filter__item');
    $(CARDS_SECTION).on('click', function () {
      const PAGINATION_SECTION = $(elm).find('[data-paginated="pagination"]')[0];
      const LIST_SECTION = $(elm).find('[data-paginated="list"]')[0];

      let perPage = 6;
      if ($(window).width() < 1280) {
        perPage = 6;
      }
      if ($(window).width() < 960) {
        perPage = 6;
      }
      if ($(window).width() < 640) {
        perPage = 6;
      }

      const startPagination = () => {
        const OFFERS_SECTION = $(elm).find('.list__item');
        $(OFFERS_SECTION).each(function (index, item) {
          $(LIST_SECTION).find(`> *.active`).hide();
          $(LIST_SECTION).find(`> *.active:lt(${perPage})`).show();
        });
      };

      const hidePagination = () => {
        if ($(LIST_SECTION).find(`> *:visible`).length >= $(LIST_SECTION).find(`> *.active`).length) {
          $(PAGINATION_SECTION).hide();
        } else {
          $(PAGINATION_SECTION).show();
        }
      };

      startPagination();
      hidePagination();

      $(elm)
        .find(PAGINATION_SECTION)
        .on('click', '[data-paginated-button="next"]', function () {
          const nextPage = parseInt($(this).attr('data-paginated-currpage')) + 1;
          const filterButton = $(elm).find('[data-paginated-button="next"]').attr('data-filter');
          $(LIST_SECTION)
            .find(`> *.active:lt(${perPage * nextPage}), [data-filter="${filterButton}"]`)
            .show();
          $(this).attr('data-paginated-currpage', nextPage);
          hidePagination();
        });

      const OFFERS_TAG = $(elm).find('.filter__tag-item');
      $(OFFERS_TAG).on('click', function () {
        const filterTagsItem = $('.filter__tag-item.active').attr('data-filter-tags');
        const listOfferItem = $('.list__item').attr('data-filter');
        const filterCardItem = $('.filter__item.active').attr('data-filter');

        if (filterTagsItem === 'todos') {
          const PAGINATION_SECTION = $(elm).find('[data-paginated="pagination"]')[0];
          const LIST_SECTION = $(elm).find('[data-paginated="list"]')[0];

          let perPage = 6;
          if ($(window).width() < 1280) {
            perPage = 6;
          }
          if ($(window).width() < 960) {
            perPage = 6;
          }
          if ($(window).width() < 640) {
            perPage = 6;
          }

          const startPagination = () => {
            const OFFERS_SECTION = $(elm).find('.list__item');
            $(OFFERS_SECTION).each(function (index, item) {
              $(LIST_SECTION).find(`> *.active`).hide();
              $(LIST_SECTION).find(`> *.active:lt(${perPage})`).show();
            });
          };

          const hidePagination = () => {
            if ($(LIST_SECTION).find(`> *:visible`).length >= $(LIST_SECTION).find(`> *.active`).length) {
              $(PAGINATION_SECTION).hide();
            } else {
              $(PAGINATION_SECTION).show();
            }
          };

          startPagination();
          hidePagination();

          $(elm)
            .find(PAGINATION_SECTION)
            .on('click', '[data-paginated-button="next"]', function () {
              const nextPage = parseInt($(this).attr('data-paginated-currpage')) + 1;
              const filterButton = $(elm).find('[data-paginated-button="next"]').attr('data-filter');
              $(LIST_SECTION)
                .find(`> *.active:lt(${perPage * nextPage}), [data-filter="${filterButton}"]`)
                .show();
              $(this).attr('data-paginated-currpage', nextPage);
              hidePagination();
            });
        }
      });
    });

    const OFFERS_TAG = $(elm).find('.filter__tag-item');
    $(OFFERS_TAG).on('click', function () {
      const filterTagsItem = $('.filter__tag-item.active').attr('data-filter-tags');
      const listOfferItem = $('.list__item').attr('data-filter');
      const filterCardItem = $('.filter__item.active').attr('data-filter');

      if (filterTagsItem === 'todos') {
        if (listOfferItem === filterCardItem) {
          const PAGINATION_SECTION = $(elm).find('[data-paginated="pagination"]')[0];
          const LIST_SECTION = $(elm).find('[data-paginated="list"]')[0];

          let perPage = 6;
          if ($(window).width() < 1280) {
            perPage = 6;
          }
          if ($(window).width() < 960) {
            perPage = 6;
          }
          if ($(window).width() < 640) {
            perPage = 6;
          }

          const startPagination = () => {
            const OFFERS_SECTION = $(elm).find('.list__item');
            $(OFFERS_SECTION).each(function (index, item) {
              $(LIST_SECTION).find(`> *.active`).hide();
              $(LIST_SECTION).find(`> *.active:lt(${perPage})`).show();
            });
          };

          const hidePagination = () => {
            if ($(LIST_SECTION).find(`> *:visible`).length >= $(LIST_SECTION).find(`> *.active`).length) {
              $(PAGINATION_SECTION).hide();
            } else {
              $(PAGINATION_SECTION).show();
            }
          };

          startPagination();
          hidePagination();

          $(elm)
            .find(PAGINATION_SECTION)
            .on('click', '[data-paginated-button="next"]', function () {
              const nextPage = parseInt($(this).attr('data-paginated-currpage')) + 1;
              const filterButton = $(elm).find('[data-paginated-button="next"]').attr('data-filter');
              $(LIST_SECTION)
                .find(`> *.active:lt(${perPage * nextPage}), [data-filter="${filterButton}"]`)
                .show();
              $(this).attr('data-paginated-currpage', nextPage);
              hidePagination();
            });
        }
      }
    });
  };

  const hideSections = () => {
    const OFFERS_SECTION_ACTIVE = $(elm).find('.list__item.active');
    const CARDS_SECTION = $(elm).find('.filter__item');

    if (OFFERS_SECTION_ACTIVE.length <= 0 && CARDS_SECTION.length > 0) {
      $(elm).find('.filter-offer_offers__list').hide();
      $(elm).find('.filter-offer_alert').hide();
      $(elm).find('.filter-offer_offers__list-alert').show();
    } else if (OFFERS_SECTION_ACTIVE.length <= 0 && CARDS_SECTION.length <= 0) {
      $(elm).find('.filter-offer_alert').show();
      $(elm).find('.filter-offer_offers__list-alert').hide();
      $(elm).find('.filter-offer_offers__list').hide();
    } else {
      $(elm).find('.filter-offer_alert').hide();
      $(elm).find('.filter-offer_offers__list-alert').hide();
    }
  };
}
