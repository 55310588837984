export default function menu({ main, elm }) {
  window.dataLayer = window.dataLayer || [];
  const toggle = `[data-action="menu-toggle"]`;

  main((_) => [init]);
  const init = () => {
    $(toggle).on('click', function () {
      // TO-DO: DRY - close and reset search in case it was opened
      $(`[data-action="search-toggle"]`).removeClass('active');
      $('[data-component="search"]').removeClass('active');
      $('body').removeClass('overflow');
      show($(this));
    });
  };
  const show = (currToggle) => {
    $(elm).toggleClass('active');
    if ($(currToggle).hasClass('active')) {
      $(toggle).removeClass('active');
      $(elm).removeClass('active');

      dataLayer.push({
        event: 'interacao',
        evCategory: 'menu-hamburguer',
        evAction: 'clique-menu',
        evLabel: 'fechar',
      });
    } else {
      $(toggle).removeClass('active');
      $(currToggle).addClass('active');
      $(elm).addClass('active');

      dataLayer.push({
        event: 'interacao',
        evCategory: 'menu-hamburguer',
        evAction: 'clique-menu',
        evLabel: 'abrir',
      });
    }
  };
}
