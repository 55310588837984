export default function servicepercard({ main, elm }) {
  main((_) => [init, list]);
  const init = () => {};
  const list = () => {
    const FILTER_SECTION = $(elm).find('[data-filter]')[0];
    const LIST_SECTION = $(elm).find('[data-list]')[0];

    // reset select for when the back button is pressed

    // fill partner select
    $(FILTER_SECTION)
      .find('select[data-filter-by="partner"]')
      .each(function (index, item) {
        $(item).select2({
          dropdownAutoWidth: true,
          minimumResultsForSearch: -1,
        });
        const selectOptions = unique(
          $(LIST_SECTION)
            .find('> *')
            .map(function (index, item) {
              return [...$(this).attr(`data-partner`).split(',')];
            }),
        );
        $.each(selectOptions, function (i, value) {
          $(item).append($('<option></option>').val(value).html(value));
        });
      });

    // on change of partner select, check if there's more than one card. if there's more then show and fill cardname select
    $(FILTER_SECTION)
      .find('select[data-filter-by="partner"]')
      .on('change', function () {
        const partner = $(this).val();
        if ($(`[data-partner="${partner}"]`).length <= 1) {
          $(`[data-partner]`).removeClass('active');
          $(`[data-partner="${partner}"]`).addClass('active');
          $(`.filter__select:nth-child(2)`).hide();
        } else {
          $(`[data-partner]`).removeClass('active');
          $(`.filter__select:nth-child(2)`).show();
          $(FILTER_SECTION).find('select[data-filter-by="cardname"]').empty();
          $(FILTER_SECTION).find('select[data-filter-by="cardname"]').append($('<option selected disabled>Cartão</option>'));
          $(FILTER_SECTION).find('select[data-filter-by="cardname"]').addClass('active');
          $(FILTER_SECTION)
            .find('select[data-filter-by="cardname"]')
            .each(function (index, item) {
              $(item).select2({
                dropdownAutoWidth: true,
                minimumResultsForSearch: -1,
              });
              const selectOptions = unique(
                $(LIST_SECTION)
                  .find(`> *[data-partner="${partner}"]`)
                  .map(function (index, item) {
                    return [...$(this).attr(`data-cardname`).split(',')];
                  }),
              );
              $.each(selectOptions, function (i, value) {
                $(item).append($('<option></option>').val(value).html(value));
              });
            });
        }
      });

    // on change of cardname select, show correct service number
    $(FILTER_SECTION)
      .find('select[data-filter-by="cardname"]')
      .on('change', function () {
        const cardname = $(this).val();
        $(`[data-cardname]`).removeClass('active');
        $(`[data-cardname="${cardname}"]`).addClass('active');
      });
  };
  const unique = (list) => {
    const result = [];
    $.each(list, function (i, e) {
      if ($.inArray(e, result) == -1) result.push(e);
    });
    return result.filter(function (e) {
      return e;
    });
  };
}
