export default function search({ main, elm }) {
  window.dataLayer = window.dataLayer || [];
  const toggle = `[data-action="search-toggle"]`;
  const close = `[data-action="search-close"]`;

  main((_) => [init, form]);
  const init = () => {
    $(toggle).on('click', function () {
      // TO-DO: DRY - close and reset menu in case it was opened
      $(`[data-action="menu-toggle"]`).removeClass('active');
      $('[data-component="menu"]').removeClass('active');
      $('body').removeClass('overflow');
      show($(this));
    });
    $(close).on('click', function () {
      hide($(this));
    });
  };
  const show = (currToggle) => {
    $(elm).toggleClass('active');
    if ($(currToggle).hasClass('active')) {
      $(toggle).removeClass('active');
      $(elm).removeClass('active');
      $('body').removeClass('overflow');
    } else {
      $(toggle).removeClass('active');
      $(currToggle).addClass('active');
      $(elm).addClass('active');
      $('body').addClass('overflow');
    }
  };
  const hide = () => {
    $(toggle).removeClass('active');
    $(elm).removeClass('active');
    $('body').removeClass('overflow');
    dataLayer.push({
      event: 'interacao',
      evCategory: 'buscar',
      evAction: 'clique',
      evLabel: 'fechar',
    });
  };
  const form = () => {
    $(elm)
      .find('form')
      .on('submit', function (e) {
        if ($(elm).find('form input').val().length !== 0) {
          const inputValue = $(elm).find('form input').val();
          const text = inputValue
            .trim()
            .toLowerCase()
            .normalize('NFD')
            .replace(/[^a-zA-Z0-9 -]/g, '')
            .replace(/\s+/g, '-');
          dataLayer.push({
            event: 'interacao',
            evCategory: 'buscar',
            evAction: 'clique-lupa',
            evLabel: `${text}`,
          });
          return true;
        } else {
          return false;
        }
      });
  };
}
