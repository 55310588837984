import 'slick-carousel/slick/slick';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default function offerlist({ main, elm }) {
  const couponCode = `.coupon__code`;
  main((_) => [init, carousel, tooltip, copy]);
  const init = () => {};
  const carousel = () => {
    $(elm)
      .find('[data-carousel]')
      .on('init', function (event, slick, currentSlide, nextSlide) {
        if (slick.slideCount < 2) {
          $(elm).find('[data-carousel] .slick-track').addClass('single-item');
        }
      });
    $(elm).find('[data-carousel]').slick({
      arrows: false,
      dots: true,
      slidesToShow: 2,
      slidesToScroll: 2,
      variableWidth: true,
      infinite: true,
      centerMode: true,
    });
  };
  const tooltip = () => {
    tippy(couponCode, {
      content: 'O código do cupom foi copiado',
      trigger: 'click',
      theme: 'bradescard',
      onShow(instance) {
        setTimeout(() => {
          instance.hide();
        }, 2000);
      },
    });
  };
  const copy = () => {
    $(couponCode).on('click', function () {
      const $temp = $('<input>');
      $('body').append($temp);
      $temp.val($(this).text()).select();
      document.execCommand('copy');
      $temp.remove();
    });
  };
}
