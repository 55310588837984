export default function tracking({ main, elm }) {
  main((_) => [tracking]);

  const tracking = () => {
    window.dataLayer = window.dataLayer || [];
    $('body').on('click', '.js-tracking', function () {
      const $self = $(this);
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: $self.data('label'),
      });
    });
    // tracking página cartões geral
    $('.cartoes').load('.cartoes', function () {
      const $self = $(this);
      dataLayer.push({
        event: $self.data('event'),
        virtualPageUrl: $self.data('url'),
        virtualPageTitle: $self.data('title'),
      });
    });
    $('.cartoes .filter__select').on('change', function () {
      const $self = $(this);
      const retiraAcento = $(this)
        .find(':selected')
        .text()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const selectedFilter = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: $self.data('label') + selectedFilter,
      });
    });
    $('.cartoes .link__secondary').on('click', function () {
      const $self = $(this);
      const retiraAcento = $self
        .data('label')
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const selectedMore = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: selectedMore,
      });
    });
    $('.cartoes .link__primary').on('click', function () {
      const $self = $(this);
      const retiraAcento = $self
        .data('label')
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const selectedAsk = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: selectedAsk,
      });
    });
    $('.cartoes .pagination__button').on('click', function () {
      const $self = $(this);
      const retiraAcento = $self
        .text()
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const selectedMoreCards = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: selectedMoreCards,
      });
    });
    // tracking página cartão escolhido
    if ($('body').hasClass('cartao')) {
      const $self = $('body');
      const title = $self.find('.card .item__title').text();
      const retiraAcento = title
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titlePageCard = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        virtualPageUrl: $self.data('url') + titlePageCard,
        virtualPageTitle: $self.data('title'),
      });
    }
    $('.cartao .card__button').on('click', function () {
      const $self = $(this);
      const title = $('.card').find('.item__title').text();
      const retiraAcento = $self
        .text()
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const selectedMoreCards = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      const retiraAcento2 = title
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titlePageCard = retiraAcento2.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category') + titlePageCard,
        evAction: $self.data('action'),
        evLabel: selectedMoreCards,
      });
    });
    $('.cartao .banner-flash a').on('click', function () {
      const $self = $(this);
      const label = $self.data('label');
      const retiraAcento = label
        .trim()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      dataLayer.push({
        event: 'interacao',
        evCategory: 'conheca-beneficios',
        evAction: 'clique-banner',
        evLabel: retiraAcento,
      });
    });
    $('.coupon__code').on('click', function () {
      const $self = $(this);
      const retiraAcento = $self
        .text()
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const couponCode = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: couponCode,
      });
    });
    $('.cartao .item__links .link__primary').on('click', function () {
      const $self = $(this);
      const getLabel = $self.data('label');
      const retiraAcento = getLabel
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: titleLabel,
      });
    });
    $('.cartao .offer-list .item__link').on('click', function () {
      const $self = $(this);
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: $self.data('label'),
      });
    });
    $('.cartao .assistance .filter__button').on('click', function () {
      const $self = $(this);
      const getLabel = $self.text();
      const retiraAcento = getLabel
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: titleLabel,
      });
    });
    $('.cartao .assistance .item__back .numbers__link').on('click', function () {
      const $self = $(this);
      const getTextLabel = $self.attr('href');
      const retiraAcento = getTextLabel
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      const getNameAction = $self.data('action');
      const retiraAcento2 = getNameAction
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleAction = retiraAcento2.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: titleAction,
        evLabel: titleLabel,
      });
    });
    $('.cartao .assistance .item__back .buttons__button--secondary').on('click', function () {
      const $self = $(this);
      const getTextLabel = $self.data('label');
      const retiraAcento = getTextLabel
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      const getNameAction = $self.data('action');
      const retiraAcento2 = getNameAction
        .replace('open-modal ', '')
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleAction = retiraAcento2.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: titleAction,
        evLabel: titleLabel,
      });
    });
    $('.cartao .assistance .item__back .external').on('click', function () {
      const $self = $(this);
      const getTextLabel = $self.data('label');
      const retiraAcento = getTextLabel
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      const getNameAction = $self.data('action');
      const retiraAcento2 = getNameAction
        .replace('open-modal ', '')
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleAction = retiraAcento2.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: titleAction,
        evLabel: titleLabel,
      });

    });
    $('.cartao .assistance .item__back .buttons__button--primary').on('click', function () {
      const $self = $(this);
      const getTextLabel = $self.data('label');
      const retiraAcento = getTextLabel
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      const getNameAction = $self.data('action');
      const retiraAcento2 = getNameAction
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleAction = retiraAcento2.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: titleAction,
        evLabel: titleLabel,
      });
    });
    $('.cartao .video-list .view__video').on('click', function () {
      const $self = $(this);
      const getLabel = $(this).parent().find('.view__title').text();
      const retiraAcento = getLabel
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: titleLabel,
      });
    });
    $('.cartao .faq-list .box__title').on('click', function () {
      const $self = $('.faq-list').find('.box');
      const getLabel = $(this).text();
      const retiraAcento = getLabel
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: titleLabel,
      });
    });
    $('.cartao .faq-list .item__title').on('click', function () {
      const $self = $('.faq-list').find('.box__item');
      const getLabel = $(this).text();
      const retiraAcento = getLabel
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: titleLabel,
      });
    });
    $('.service-online .filter__option--input').on('click', function () {
      const $self = $('.filter__option');
      const checked = $self.find(':checked');
      const checkedTitle = checked.next('.filter__option--text').text();
      const retiraAcento = checkedTitle
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const checkedTitleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: checkedTitleLabel,
      });
    });
    $('.service-online .filter__switch').on('click', function () {
      const $self = $('.filter__option');
      const checked = $self.find(':checked');
      const checkedTitle = checked.next('.filter__option--text').text();
      const retiraAcento = checkedTitle
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const checkedTitleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: checkedTitleLabel,
      });
    });
    $('.service-online .services__item').on('click', function () {
      const $self = $(this);
      const labelTitle = $self.data('label');
      const retiraAcento = labelTitle
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const TitleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: TitleLabel,
      });
    });
    $('.menu .menu__link').on('click', function () {
      const $self = $(this);
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: $self.data('label'),
      });
    });
    $('.footer .internal__link').on('click', function () {
      const $self = $(this);
      const retiraAcento = $self
        .data('label')
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: 'conheca-beneficios',
        evAction: $self.data('action'),
        evLabel: titleLabel,
      });
    });
    $('.footer .external__link').on('click', function () {
      const $self = $(this);
      const retiraAcento = $self
        .data('label')
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: 'conheca-beneficios',
        evAction: $self.data('action'),
        evLabel: titleLabel,
      });
    });
    // Página de ofertas
    $('.ofertas .filter__select').on('change', function () {
      const retiraAcento = $(this)
        .find(':selected')
        .text()
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const selectedFilter = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: 'interacao',
        evCategory: 'oferta-cartao',
        evAction: 'clique-parceiros',
        evLabel: selectedFilter,
      });
    });
    $('.ofertas .filter-offer .item__image').on('click', function () {
      const $self = $(this);
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: $self.data('label'),
      });
    });
    $('.ofertas .filter-offer .item__link').on('click', function () {
      const $self = $(this);
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: $self.data('label'),
      });
    });
    $('.ofertas .filter-offer_filter__tags .filter__tag-item').on('click', function () {
      const $self = $(this);
      const getLabel = $self.text();
      dataLayer.push({
        event: 'interacao',
        evCategory: 'oferta-cartao',
        evAction: 'clique-filtro',
        evLabel: getLabel,
      });
    });
    // Página Cartões de Parceiros Descontinuados
    $('.cartoes-descontinuados .filter__select').on('change', function () {
      const retiraAcento = $(this)
        .find(':selected')
        .text()
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const selectedFilter = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: 'interacao',
        evCategory: 'cartao-descontinuado',
        evAction: 'clique-selecionar',
        evLabel: selectedFilter,
      });
    });
    $('.cartoes-descontinuados .card__item__more-info a').on('click', function () {
      const $self = $(this);
      const nameCard = $self
        .parent()
        .parent()
        .find('h3')
        .text()
        .trim()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '')
        .replace(/\s+/g, '-');
      const nameLink = $self
        .text()
        .trim()
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 -]/g, '')
        .replace(/\s+/g, '-');
      dataLayer.push({
        event: 'interacao',
        evCategory: 'cartao-descontinuado',
        evAction: 'clique-card',
        evLabel: `${nameCard}-${nameLink}`,
      });
    });
    // Página Seguros Descontinuados
    $('.seguros-descontinuados .insurance__item__more-info a').on('click', function () {
      const $self = $(this);
      const nameCard = $self
        .parent()
        .parent()
        .find('h3')
        .text()
        .trim()
        .toLowerCase()
        .normalize('NFD')
        .replace(/-/g, '')
        .replace(/[^a-zA-Z0-9 -]/g, '')
        .replace(/\s+/g, '-');
      const nameLink = $self
        .text()
        .trim()
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 -]/g, '')
        .replace(/\s+/g, '-');
      dataLayer.push({
        event: 'interacao',
        evCategory: 'seguro-descontinuado',
        evAction: 'clique-card',
        evLabel: `${nameCard}-${nameLink}`,
      });
    });
    // Página Atendimento
    $('.atendimento .faq-list .box__title').on('click', function () {
      const $self = $('.faq-list').find('.box');
      const getLabel = $(this).text();
      const retiraAcento = getLabel
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: titleLabel,
      });
    });
    $('.atendimento .faq-list .item__title').on('click', function () {
      const $self = $('.faq-list').find('.box__item');
      const getLabel = $(this).text();
      const retiraAcento = getLabel
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: titleLabel,
      });
    });
    $('.atendimento .filter__select #partner').on('change', function () {
      const retiraAcento = $(this).find(':selected').text().trim().normalize('NFD');
      const selectedFilter = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: 'interacao',
        evCategory: 'precisa-de-ajuda',
        evAction: 'clique-selecionar-parceiros',
        evLabel: selectedFilter,
      });
    });
    $('.atendimento .filter__select #cardname').on('change', function () {
      const retiraAcento = $(this).find(':selected').text().trim().normalize('NFD');
      const selectedFilter = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: 'interacao',
        evCategory: 'precisa-de-ajuda',
        evAction: 'clique-selecionar-cartao',
        evLabel: selectedFilter,
      });
    });
    $('.atendimento .card-discontinued-cta__link').on('click', function () {
      const retiraAcento = $(this)
        .text()
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const nameLink = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: 'interacao',
        evCategory: 'precisa-de-ajuda',
        evAction: 'clique-descontinuado',
        evLabel: nameLink,
      });
    });
    $('.atendimento #cancel-channel-cta a').on('click', function () {
      const retiraAcento = $(this)
        .text()
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const nameLink = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: 'interacao',
        evCategory: 'precisa-de-ajuda',
        evAction: 'clique-cancelamento',
        evLabel: nameLink,
      });
    });
    // Página Home
    $('.home .video-list .view__video').on('click', function () {
      const $self = $(this);
      const getLabel = $(this).parent().find('.view__title').text();
      const retiraAcento = getLabel
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: $self.data('action'),
        evLabel: titleLabel,
      });
    });
    // Sazonal - cta imagem
    $('.box-partnership .box-partnership__content .box-partnership__texts .buttons__button--primary').on('click', function () {
      const $self = $(this);
      const getTextLabel = $self.data('label');
      const retiraAcento = getTextLabel
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      const getNameAction = $self.data('action');
      const retiraAcento2 = getNameAction
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleAction = retiraAcento2.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: titleAction,
        evLabel: titleLabel,
      });
    });
    // Sazonal - cta texto
    $('.box-warning .box-warning__content  .buttons__button--primary').on('click', function () {
      const $self = $(this);
      const getTextLabel = $self.data('label');
      const retiraAcento = getTextLabel
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleLabel = retiraAcento.toLowerCase().replace(/\s+/g, '-');
      const getNameAction = $self.data('action');
      const retiraAcento2 = getNameAction
        .trim()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/g, '');
      const titleAction = retiraAcento2.toLowerCase().replace(/\s+/g, '-');
      dataLayer.push({
        event: $self.data('event'),
        evCategory: $self.data('category'),
        evAction: titleAction,
        evLabel: titleLabel,
      });
    });
  };
}
