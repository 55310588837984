export default function assistancemodal({ main, elm }) {
  const button = `[data-action*="open-modal"]`;
  const buttonClose = `[data-action*="close-modal"]`;

  main((_) => [init, navigation, open, close]);
  const init = () => {};
  const navigation = () => {
    $(elm).find(`button.prev`).hide();
    $(elm)
      .find('button.next')
      .on('click', function () {
        const pageParent = $(this).parent().parent();
        const currPage = pageParent.find('.modal__page.active').index();
        const nextPage = currPage + 1;
        pageParent.find('.modal__page').removeClass('active');
        pageParent.find('.modal__page').eq(nextPage).addClass('active');
        pageParent.find('button.prev').show();
        if (nextPage === pageParent.find('.modal__page').length - 1) {
          $(this).hide();
        }
      });
    $(elm)
      .find('button.prev')
      .on('click', function () {
        const pageParent = $(this).parent().parent();
        const currPage = pageParent.find('.modal__page.active').index();
        const prevPage = currPage - 1;
        pageParent.find('.modal__page').removeClass('active');
        pageParent.find('.modal__page').eq(prevPage).addClass('active');
        pageParent.find('button.next').show();
        if (prevPage === 0) {
          $(this).hide();
        }
      });
  };
  const open = () => {
    $(button).on('click', function () {
      $(elm).removeClass('active');
      $(elm).find('.assistance__modal').addClass('active');
      $(elm)
        .find(`.assistance__modal .modal#${$(this).attr('data-id')}`)
        .addClass('active');
      // // calculate if show next and prev arrows
      if ($(`.assistance__modal .modal#${$(this).attr('data-id')} .modal__page`).length <= 1) {
        $(`.assistance__modal .modal#${$(this).attr('data-id')} .modal__navigation`).remove();
      }
    });
  };
  const close = () => {
    $(buttonClose).on('click', function () {
      $(elm).removeClass('active');
      $(elm).find('.assistance__modal').removeClass('active');
      $(elm).find(`.assistance__modal .modal`).removeClass('active');
    });
  };
}
