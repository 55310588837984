export default function videolist({ main, elm }) {
  main((_) => [init, setActive, play, filter]);
  const init = () => {
    $(elm)
      .find('.list__item')
      .on('click', function () {
        $(this).parent().find('.list__item.active').removeClass('active');
        $(this).addClass('active');
        $(elm).find('.view').removeClass('active');
        setActive();
      });
  };
  const filter = () => {
    $(elm)
      .find('.list__tags .tag')
      .on('click', function () {
        $(elm).find('.list__tags .tag').removeClass('active');
        $(elm).find(`.list__overflow`).removeClass('active');
        $(elm)
          .find(`.list__overflow#${$(this).attr('data-id')}`)
          .addClass('active');
        $(this).addClass('active');
        setActive();
      });
  };
  const play = () => {
    $(elm)
      .find('.view__video')
      .on('click', function () {
        const currurl = $(this).find('iframe').attr('src');
        const autoplayUrl = currurl.indexOf('autoplay=1') > -1 ? currurl : `${currurl}?autoplay=1`;
        $(this).find('iframe').attr('src', autoplayUrl);
        $(elm).find('.view').addClass('active');
        $(elm).find('.view__video img').hide();
        $(elm).find('.view__video iframe').show();
      });
  };
  const setActive = () => {
    const curr = $(elm).find('.list__overflow.active .list__item.active');
    $(elm).find('.view__video img').show();
    $(elm).find('.view__video iframe').hide();
    $(elm)
      .find('.view__video iframe')
      .attr('src', `${curr.data('video-url').replace('watch?v=', 'embed/')}`);
    $(elm)
      .find('.view__video img')
      .attr('src', `${curr.find('.item__image img').attr('src')}`);
    $(elm)
      .find('.view__title')
      .text(`${curr.find('.content__title').text()}`);
    $(elm)
      .find('.view__description')
      .text(`${curr.find('.content__description').text()}`);
  };
}
