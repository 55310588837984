import List from 'list.js';
import './vendors/select2';

export default function discontinuedlist({ main, elm }) {
  main((_) => [init, select, list]);

  const init = () => {};
  const select = () => {
    $(elm).find('select').select2({
      dropdownAutoWidth: true,
      minimumResultsForSearch: -1,
    });
  };
  const list = () => {
    const FILTER_SECTION = $(elm).find('[data-paginated="filter"]')[0];
    const LIST_SECTION = $(elm).find('[data-paginated="list"]')[0];
    const PAGINATION_SECTION = $(elm).find('[data-paginated="pagination"]')[0];

    const options = {
      valueNames: [{ data: [] }],
    };
    let perPage = 16;
    if ($(window).width() < 1280) {
      perPage = 12;
    }
    if ($(window).width() < 960) {
      perPage = 8;
    }
    if ($(window).width() < 640) {
      perPage = 4;
    }
    $(FILTER_SECTION)
      .find('select')
      .each(function (index, item) {
        const by = $(item).attr('data-paginated-filter');
        const selectOptions = unique(
          $(LIST_SECTION)
            .find('> *')
            .map(function (index, item) {
              return [...$(this).attr(`data-paginated-filter-${by}`).split(',')];
            }),
        );
        $.each(selectOptions, function (i, value) {
          $(item).append($('<option></option>').val(value).html(value));
        });
        options.valueNames[0].data.push(`paginated-filter-${by}`);
      });
    const list = new List(`${$(elm).find('[data-type="paginated"]').attr('id')}`, options);

    const listUpdate = (list, value) => {
      list.filter((item) => {
        return null || item.values()[`paginated-filter-partner`].indexOf(value) > -1;
      });
      list.update();
      startPagination();
      hidePagination();
    };

    $(FILTER_SECTION)
      .find('select')
      .on('change', function () {
        const value = this.value;
        $(this).find(`option`).prop('disabled', false);
        $(this).find(`option[value="${value}"]`).attr('disabled', 'disabled');
        listUpdate(list, value);
      });
    const startPagination = () => {
      $(LIST_SECTION).find(`> *`).hide();
      $(LIST_SECTION).find(`> *:lt(${perPage})`).show();
    };

    const hidePagination = () => {
      if ($(LIST_SECTION).find(`> *:visible`).length >= $(LIST_SECTION).find(`> *`).length) {
        $(PAGINATION_SECTION).hide();
      } else {
        $(PAGINATION_SECTION).show();
      }
    };
    startPagination();
    hidePagination();
    $(elm)
      .find(PAGINATION_SECTION)
      .on('click', '[data-paginated-button="next"]', function () {
        const nextPage = parseInt($(this).attr('data-paginated-currpage')) + 1;
        $(LIST_SECTION)
          .find(`> *:lt(${perPage * nextPage})`)
          .show();
        $(this).attr('data-paginated-currpage', nextPage);
        hidePagination();
      });
  };
  const unique = (list) => {
    const result = [];
    $.each(list, function (i, e) {
      if ($.inArray(e, result) == -1) result.push(e);
    });
    return result.filter(function (e) {
      return e;
    });
  };
}
