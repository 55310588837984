import 'slick-carousel/slick/slick';

export default function assistancelist({ main, elm }) {
  main((_) => [init, carousel, filter, toggle]);
  const init = () => {};
  const carousel = () => {
    $(elm)
      .find('[data-carousel]')
      .slick({
        arrows: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: false,
        rows: 0,
        responsive: [
          {
            breakpoint: 1279,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              infinite: false,
            },
          },
          {
            breakpoint: 959,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              infinite: false,
            },
          },
        ],
      });
  };
  const filter = () => {
    $(elm)
      .find('[data-filter] button')
      .on('click', function () {
        $(elm).find('[data-filter] button').removeClass('active');
        $(this).addClass('active');
        $(elm).find(`[data-carousel]`).removeClass('active');
        $(elm)
          .find(`[data-carousel]#${$(this).attr('data-id')}`)
          .addClass('active');
      });
  };
  const toggle = () => {
    $(elm)
      .find('.item__button')
      .on('click', function () {
        const $self = $(this);

        if ($(this).parent().hasClass('active')) {
          $(elm).find('.assistance__item').removeClass('active');

          const getNameAction = $(this).parent().find('.content__title').text();
          const retiraAcento = getNameAction
            .trim()
            .normalize('NFD')
            .replace(/[^a-zA-Z0-9 -]/g, '');
          const titleAction = retiraAcento.toLowerCase().replace(/\s+/g, '-');

          dataLayer.push({
            event: $self.data('event'),
            evCategory: $self.data('category'),
            evAction: $self.data('action') + titleAction,
            evLabel: 'fechar',
          });
        } else {
          $(elm).find('.assistance__item').removeClass('active');
          $(this).parent().toggleClass('active');

          const getNameAction = $(this).parent().find('.content__title').text();
          const retiraAcento = getNameAction
            .trim()
            .normalize('NFD')
            .replace(/[^a-zA-Z0-9 -]/g, '');
          const titleAction = retiraAcento.toLowerCase().replace(/\s+/g, '-');

          dataLayer.push({
            event: $self.data('event'),
            evCategory: $self.data('category'),
            evAction: $self.data('action') + titleAction,
            evLabel: 'abrir',
          });
        }
      });
  };
}
