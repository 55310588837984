export default function requestfollowup({ main, elm }) {
  main((_) => [init]);
  const init = () => {
    $(elm)
      .find('form')
      .on('submit', function (e) {
        e.preventDefault();
        $('#testLoad').load('https://wspf.bradesco.com.br/wsBradescard/ouvidoria.aspx?protocolo=' + $(elm).find('form input').val());
        $('#testLoad').remove();
        // alert('Protocolo enviado com sucesso.');
        $(elm).find('.popup__success').addClass('active');
        setTimeout(() => {
          $(elm).find('.popup__success').removeClass('active');
        }, 5000);
      });
  };
}
