export default function chat({ main, elm }) {
  main((_) => [init]);

  const init = () => {
    $(elm).on('click', function (event) {
      event.preventDefault ? event.preventDefault() : (event.returnValue = false);

      var URL = $(this).attr('href');
      var width = 505;
      var height = 650;
      var left = 99;
      var top = 99;
      window.open(
        URL,
        'janela',
        'width=' +
          width +
          ', height=' +
          height +
          ', top=' +
          top +
          ', left=' +
          left +
          ', scrollbars=yes, status=no, toolbar=no, location=no, directories=no, menubar=no, resizable=no, fullscreen=no',
      );
    });
  };
}
