export default function backbutton({ main, elm }) {
  const isNotDirect = document.referrer; // if user access page directly this value will be undefined, therefore it should not show the button
  main((_) => [init]);

  const init = () => {
    if (isNotDirect) {
      $(elm).addClass('active');
      $(elm).on('click', function () {
        window.history.back();
      });
    }
  };
}
