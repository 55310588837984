export default function searchresult({ main, elm }) {
  main((_) => [init]);
  const init = () => {
    const dataSrc = $('#iframeSearch').attr('data-src');
    $('#iframeSearch').attr('src', dataSrc.replace('[TERMO]', getUrlParameter('termo')));
  };
  const getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
    return false;
  };
}
