import 'intersection-observer';

export default function cardinfo({ main, elm }) {
  main((_) => [init]);
  const init = () => {
    if ($('#card-info-sticky')[0]) {
      observer.observe($('#card-info-sticky')[0]);
    }
  };
  const observer = new IntersectionObserver(
    function (entries) {
      if (entries[0].intersectionRatio === 0) $(elm).addClass('scrolled');
      else if (entries[0].intersectionRatio === 1) $(elm).removeClass('scrolled');
    },
    { threshold: [0, 1] },
  );
}
