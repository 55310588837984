export default function legaltext({ main, elm }) {
  main((_) => [init]);

  const init = () => {
    $(elm)
      .find('.heading')
      .on('click', function () {
        $(this).toggleClass('active');
        $(this).next('.content').slideToggle().toggleClass('active');
      });
  };
}
